import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { createI18n } from 'vue-i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyLoad from 'vue3-lazyload'
import VueDragscroll from "vue-dragscroll";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

async function loadLocaleMessages() {
    const messages = {};
    try {
      messages['en'] = (await import('../../locales/en.json')).default;
      messages['uk'] = (await import('../../locales/uk.json')).default;
    } catch (error) {
      console.error("Failed to load locale messages", error);
    }
    return messages;
}
  

async function setupInertiaApp() {
    const messages = await loadLocaleMessages();
  
    createInertiaApp({
      title: (title) => `${title} - ${appName}`,
      resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
        setup({ el, App, props, plugin }) {
            const { locale } = props.initialPage.props; // Get the locale from server-shared props

            const i18n = createI18n({
                legacy: false,
                locale: locale || 'en', // Use the server-provided locale
                messages
            });

            const VueApp = createApp({ render: () => h(App, props) });

            VueApp.config.globalProperties.baseUrl = props.initialPage.props.domain;

            VueApp.use(VueAxios, axios)
                .use(plugin)
                .use(ZiggyVue, Ziggy)
                .use(VueLazyLoad)
                .use(VueDragscroll)
                .use(i18n)
                .mount(el);

            return VueApp;
        },
      progress: {
          color: '#4B5563',
      },
    });
  }

  setupInertiaApp();
  
